/* CSS untuk elemen body */
body {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Gambar latar belakang tetap di posisi saat scroll */
  min-height: 100vh; /* Pastikan konten ada di tengah vertikal */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden; /* Menghilangkan scroll */
}

/* CSS untuk div dengan class "App" */
.App {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Atur background jadi transparan untuk mengakomodasi gambar latar belakang */
}

/* CSS untuk elemen dengan class "section" */
.section {
  padding: 1rem;
  border-radius: 10px;
}

/* CSS untuk elemen dengan class "modal-content" */
.modal-content {
  overflow: hidden; /* Menghilangkan scroll */
  padding: 1rem;
  border-radius: 10px;
  color: white; /* Warna teks menjadi putih */
}

.modal-content .columns .column img {
  transition: transform 0.2s; /* Efek transisi ketika hover */
}

.modal-content .columns .column img:hover {
  transform: scale(1.1); /* Gambar menjadi 10% lebih besar ketika dihover */
  cursor: pointer;
}

/* CSS untuk ukuran mobile */
@media (max-width: 767px) {
  .modal-content .columns .column img {
    width: 20%; /* Gambar akan mengisi lebar kolom secara penuh */
    height: auto; /* Atur tinggi gambar sesuai aspek rasio */
    margin: 0 auto; /* Gambar akan terpusat horizontal */
  }

  .modal-content h1 {
    font-size: 2rem; /* Atur ukuran teks untuk h1 di mode mobile (is-size-4 di mode desktop) */
  }

  .modal-content h2 {
    font-size: 1.5rem; /* Atur ukuran teks untuk h2 di mode mobile (is-size-2 di mode desktop) */
  }

  /* Background untuk tampilan mobile */
  body {
    background-image: url("./asset/img/background-mobile.jpg");
  }

  /* ID wheel */
  #wheel {
    width: 100px;
    margin-left: -56px; /* Ubah nilai margin sesuai kebutuhan */
  }

  /* ID canvas */
  #canvas {
    width: 800px;
  }

  .helloBackground {
    position: relative;
    height: 300px; /* Sesuaikan tinggi gambar sesuai kebutuhan */
  }

  .helloText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Menempatkan teks di depan gambar */
  }

  .container-card {
    position: relative;
    text-align: center;
    color: white;
  }

  /* Centered text */
  .text-card-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -250px;
  }
}

/* Background untuk tampilan desktop */
@media (min-width: 768px) {
  body {
    background-image: url("./asset/img/background.jpg");
  }
}
